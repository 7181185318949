import React, { useEffect} from 'react'
import './App.scss';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'
import {Navbar, Header, Footer} from './components';
import {Routes,Route} from "react-router-dom";
import {Kurumsal, Urunler, KasaElek, Contact, Kvkk, Temizleme, Ogutme, Tasima, Katalog, Paketleme} from './container';



const languages = [
  {
    code: 'tr',
    name: 'TR',
    country_code: 'tr',
  },
  {
    code: 'en',
    name: 'EN',
    country_code: 'gb',
  },
  {
    code: 'ru',
    name: 'RU',
    country_code: 'ru',
  },
  {
    code: 'de',
    name: 'DE',
    country_code: 'de',
  },
  
]
function App() {

  const currentLanguageCode = cookies.get('i18next') || 'tr'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()


  useEffect(() => {
    document.title = t('Millger®')
  }, [currentLanguage, t])
 
  return (
    
    <div className="App">  
      <Navbar/>  
        <Routes>
            <Route exact path='/' element={<Header/>} />
            <Route exact path='/Kurumsal' element={<Kurumsal/>} />
            <Route exact path='/Urunler' element={<Urunler/>} />
            <Route exact path='/KasaElek' element={<KasaElek/>} />
            <Route exact path='/Contact' element={<Contact/>} />
            <Route exact path='/Kvkk' element={<Kvkk/>} />
            <Route exact path='/Temizleme' element={<Temizleme/>} />
            <Route exact path='/Ogutme' element={<Ogutme/>} />
            <Route exact path='/Tasima' element={<Tasima/>} />
            <Route exact path='/Paketleme' element={<Paketleme/>} />
            <Route exact path='/Katalog' element={<Katalog/>} />
      </Routes> 
    <Footer/>
      
       
      <div className="language-select"> 
        <div>
        <button className='lang'> 
              {languages.map(({ code, name, country_code }) => (
                <li key={country_code}>
                  <a href='/'
                    className={classNames( {
                      disabled: currentLanguageCode === code,
                    })}
                    onClick={() => {
                      i18next.changeLanguage(code)
                    }}  
                  >
                    <span
                        className={`${country_code}`}
                      style={{
                        opacity: currentLanguageCode === code ? 0.3 : 1,
                              }}
                    ></span>
                  {name} 
                  </a>
                </li>
              ))}
              </button>
              
        </div>
      </div>
       
    </div>
    
  );
}

export default App;