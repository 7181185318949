import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//import PdfViewerComponent from '../../components/PdfViewerComponent';

function Katalog() {
    const { t } = useTranslation()
    const [showPdf, setShowPdf] = useState(false);

    useEffect(() => {
      // Bileşen yüklendiğinde showPdf'yi true yap
      setShowPdf(true);
    }, []);
  
  return (
    <div className='PDF-Viewer'>
    <div className='elekkasa'>
      <button onClick={() => setShowPdf(true)}>{t('')}</button>
      {showPdf && (
        <div className="pdf-container">
          <iframe
            src="assets/katalog.pdf"
            width="100%"
            height="900px"
            title="Katalog"
          />
        </div>
      )}
    </div>
       {/*  <PdfViewerComponent document ={'katalog.pdf'}></PdfViewerComponent> */}
        {/* <a href="katalog.pdf">{t('katalog')}</a> */}
    </div>
  );
}
export default Katalog;
