import React from 'react'

function Contact() {
  return (
    <div className='map'>
    <iframe title="millger" width="100%" height="600" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3033.1144802860604!2d34.91659491561698!3d40.51696065746094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4080d5b59eea7843%3A0xa01a23a2651d136b!2zTcSwTExHRVIgS0FSRSBFTEVLIFPEsFNURU1MRVLEsA!5e0!3m2!1str!2str!4v1661167902943!5m2!1str!2str" frameborder="0" scrolling="no" marginheight="100" marginwidth="100"></iframe>
</div>
  )
}

export default Contact